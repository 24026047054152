<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh;"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <BaseHeader :title="'Dashboard'"></BaseHeader>

      <div class="my-2">
        <div class="row">
          <div class="col-sm-8 col-12">
            <div class="card rounded shadow">
              <div class="card-header">
                <h2 class="card-title">Order stats</h2>
              </div>

              <div class="table-responsive">
                <table class="table text-nowrap">
                  <tbody>
                    <tr
                      v-for="(stat, index) in orderStats"
                      :key="`${index}stats`"
                      v-if="stat.no"
                    >
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <a
                              href="#"
                              class="btn rounded-round btn-icon btn-sm"
                              :class="`bg-${stat.color}-400`"
                            >
                              <span class="letter-icon">{{
                                stat.name.charAt(0) || ""
                              }}</span>
                            </a>
                          </div>
                          <div>
                            <a
                              href="#"
                              class="
                                text-default
                                font-weight-semibold
                                letter-icon-title
                              "
                              >{{ stat.name || "" }}</a
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="text-muted font-size-sm"
                          ><div class="text-muted font-size-sm">
                            <span
                              class="badge badge-pill"
                              :class="`bg-${stat.color || 'dark'}`"
                              >{{ stat.value || "" }}</span
                            >
                          </div></span
                        >
                      </td>
                      <td>
                        <span
                          class="font-weight-bold"
                          style="font-size: 1rem"
                          >{{ stat.no || "" }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- share link to social media -->
            <base-share></base-share>


          </div>
          <div class="col-sm-4 col-12">
            <div class="card  rounded shadow">
              <div class="card-body">
                <h2 class="card-title">Wallet Balance</h2>
                <h1 style="font-size: 3em" class="font-weight-semibold">
                  ${{ wallet_balance || "0.0" }}
                </h1>
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#walletModal"
                >
                  Topup Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup></WalletTopup>
    </BaseModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";

export default {
  name: "Dashboard",
  components: {
    WalletTopup,
  },
  computed: {
    ...mapState("dashboard", ["wallet_balance", "orderStats"]),
    ...mapState("auth", ["user"]),
    siteLink() {
      return this.user.referral.link || {};
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions("dashboard", ["_getWalletBalance", "_getOrderStats"]),
    copyToClipboard(link) {
      document.execCommand("copy");
      alert("Referral link copied to clipboard..");
    },
  },

  async mounted() {
    this.loading = true;
    await this._getWalletBalance().catch((err) => console.log(err));
    await this._getOrderStats().catch((err) => console.log(err));
    this.loading = false;
  },
};
</script>
